import { useEffect, useState } from "react";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { jwtDecode } from "jwt-decode";
import { pathBuilder } from "./path-builder";
import {
  persistValue,
  clearValue,
  retrieveValue,
} from "../../store/persistence";
import { findCountryBasedOnCountryCode, supportedCountries } from "../../store";
import i18n from "i18next";
import { VitalSigns } from "@binah/web-sdk";
import { getConfigurationVariable } from "../configuration-adapter";
import { captureException } from "../application-monitoring-adapter";
import { AnalyticsErrorEvent, trackErrorEvent } from "../analytics-adapter";

function transformObjectKeysToCamelCase(object: any) {
  Object.keys(object).forEach(function (originalKey) {
    const newKey = originalKey.charAt(0).toLowerCase() + originalKey.slice(1);

    if (newKey !== originalKey) {
      object[newKey] = object[originalKey];
      delete object[originalKey];
    }
  });
  return object;
}

const supportedVitalSigns = [
  "heartRate",
  "pulseRate",
  "oxygenSaturation",
  "breathingRate",
  "respirationRate",
  "sdnn",
  "stressLevel",
  "stressIndex",
  "bloodPressure",
  "rri",
  "meanRri",
  "rmssd",
  "sd1",
  "sd2",
  "prq",
  "pnsIndex",
  "pnsZone",
  "snsIndex",
  "snsZone",
  "wellnessLevel",
  "wellnessIndex",
  "lfhf",
  "hemoglobin",
  "hemoglobinA1c",
];

const vitalSignsMapping: { [key: string]: { name: string } } = {
  pulseRate: {
    name: "heartRate",
  },
  respirationRate: {
    name: "breathingRate",
  },
};

export function cleanseVitalSigns(vitalSigns: any) {
  let result: any = {};
  for (const key in vitalSigns) {
    if (vitalSignsMapping[key]) {
      result[vitalSignsMapping[key].name] = vitalSigns[key];
    } else if (supportedVitalSigns.indexOf(key) > -1) {
      result[key] = vitalSigns[key];
    }
  }
  if (!Object.keys(result).length) {
    result = null;
  }
  return result;
}
export interface UserRating {
  serviceType: string;
  ratedServiceId: string;
  rating: number;
  comment?: string;
}

export enum ServiceRating {
  CONSULTATION = "CONSULTATION",
}

export enum ChatBotOption {
  DIABETES = "DIABETES",
  HEALTHY_LIVING = "HEALTHY_LIVING",
}

export enum ChatBotLanguageOption {
  ENGLISH = "English",
  ZULU = "isiZulu",
}

export enum MembershipStatus {
  ACTIVE = "ACTIVE",
  PENDING = "PENDING",
  SUSPENDED = "SUSPENDED",
  CANCELLED = "CANCELLED",
  EXPIRED = "EXPIRED",
}

export enum MembershipType {
  MAIN_MEMBER = "MAIN_MEMBER",
  SPOUSE = "SPOUSE",
  CHILD = "CHILD",
  ADULT_OLDER_THAN_64 = "ADULT_OLDER_THAN_64",
  ADULT_YOUNGER_THAN_64 = "ADULT_YOUNGER_THAN_64",
}

export enum IdentityDocumentType {
  IdNumber = "IdNumber",
  PassportNumber = "PassportNumber",
  UNUID = "UNU_ID",
}

export enum IdentityDocumentTypeValue {
  ID_NUMBER = "ID_NUMBER",
  PASSPORT_NUMBER = "PASSPORT_NUMBER",
  UNUID = "UNU_ID",
}

export enum MembershipChangeRequestFormType {
  CHANGE_PLAN_FORM = "CHANGE_PLAN_FORM",
  CANCEL_PLAN_FORM = "CANCEL_PLAN_FORM",
  EDIT_DEPENDENT_FORM = "EDIT_DEPENDENT_FORM",
  REMOVE_DEPENDENT_FORM = "REMOVE_DEPENDENT_FORM",
  ADD_DEPENDENT_FORM = "ADD_DEPENDENT_FORM",
}

export type SortDirection = "Desc" | "Asc";

export interface MembershipChangeRequest {
  type: MembershipChangeRequestFormType;
  formData: {
    membershipIdentifier: string;
    dependent?: Member;
  };
}

export interface ProductBenefit {
  name: string;
  shortDescription: string | null;
  oneLiner: string | null;
  description: string | null;
  supportingDocumentUri: string | null;
  supportingInformationUri: string | null;
  waitingPeriod: string | null;
  validFrom: string | null;
  validTo: string | null;
}

export interface ProductBenefitCategory {
  name: string;
  benefits: ProductBenefit[];
}

interface ProductProvider {
  id: string;
  name: string;
  description: string | null;
  imageUri: string;
}

export enum ProductAdministratorSupportedServiceType {
  CALL_CENTRE = "CALL_CENTRE",
  MEDICAL_ASSISTANCE = "MEDICAL_ASSISTANCE",
  COUNSELLING_ASSISTANCE = "COUNSELLING_ASSISTANCE",
}

export enum ProductAdministratorSupportedServiceChannelType {
  PHONE = "PHONE",
  EMAIL = "EMAIL",
  WHATSAPP = "WHATSAPP",
}

export interface ProductAdministratorSupportedServiceChannelAvailability {
  day: string;
  operatingHours: string;
}
interface ProductAdministratorSupportedServiceChannel {
  type: ProductAdministratorSupportedServiceChannelType;
  value: string | null;
  availability: ProductAdministratorSupportedServiceChannelAvailability[];
}

export interface ProductAdministratorSupportedService {
  type: ProductAdministratorSupportedServiceType;
  channels: ProductAdministratorSupportedServiceChannel[];
}

interface ProductAdministrator {
  id: string;
  code: string;
  name: string;
  imageUri: string;
  contactDetails?: {
    callCenterNumber: string;
    callCenterEmail: string | null;
    counselingNumber: string;
    whatsappNumber: string;
  };
  callCenterNumber?: string;
  timeZone?: string;
  supportedServices?: ProductAdministratorSupportedService[];
}

export enum MemberServiceOfferingStatus {
  INACTIVE = "INACTIVE",
  ACTIVE = "ACTIVE",
  USED = "USED",
  EXPIRED = "EXPIRED",
}

export enum MemberServiceOfferingType {
  FACE_SCAN = "FACE_SCAN",
  HEALTH_RECORDS = "HEALTH_RECORDS",
  ONLINE_NURSE = "ONLINE_NURSE",
  PATHOLOGY = "PATHOLOGY",
  CONSULTATIONS = "CONSULTATIONS",
}

export interface MemberServiceOfferingUsage {
  id: string;
  serviceOfferingType: MemberServiceOfferingType;
  usageStatus: MemberServiceOfferingStatus;
  availableFrom: string;
  availableTo: string;
  linkedBenefitType: string;
  linkedBenefitName: string;
  document: string;
}

interface MemberServiceOfferingUsageSuccessResponse {
  body: MemberServiceOfferingUsage;
  total: number;
}

interface ProductUnderwriter {
  id: string;
  code: string;
  name: string;
  imageUri: string;
}

export enum ProductPremiumInterval {
  PER_MONTH = "PER_MONTH",
  PER_ANNUM = "PER_ANNUM",
}
export interface ProductPremium {
  id: string;
  memberType: string;
  premiumFormatted: string;
  interval: ProductPremiumInterval;
}

export interface GetMessages {
  appNotifications: Message[];
  skipCount: number;
  listLength: number;
  total: number;
  unreadTotal: number;
}

export interface UpdateMessage {
  id: string;
  notificationStatus: MessageStatus;
}

export enum MessageStatus {
  FAILED = "Failed",
  DELIVERED = "Delivered",
  READ = "Read",
  DELETED = "Deleted",
}

export enum MessageType {
  WELCOME = "Welcome",
  HEALTH_CHECK_REMINDER = "HealthCheckReminder",
  CONSULT_CONTINUE = "ConsultContinue",
  CONSULT_MARKETING = "ConsultMarketing",
  DANGEROUS_VITAL_WARNING = "DangerousVitalWarning",
  PRODUCT_PRAISE = "ProductPraise",
  EDUCATION_AND_ENGAGEMENT = "EducationAndEngagement",
  ONBOARDING_COMPLETE = "OnboardingComplete",
  UNLIMITED_FACE_SCAN = "UnlimitedFaceScan",
  PATHOLOGY_ORDER = "PathologyOrder",
}

export enum MessageDetailType {
  WELCOME = "Welcome",
  SATURATION_OF_PERIPHERAL_OXYGEN = "SaturationOfPeripheralOxygen",
  HEART_RATE = "HeartRate",
  BREATHING_RATE = "BreathingRate",
  DIASTOLIC_BLOOD_PRESSURE = "DiastolicBloodPressure",
  SYSTOLIC_BLOOD_PRESSURE = "SystolicBloodPressure",
  HBA1C = "HBA1C",
  DOCTOR_FOLLOW_UP = "DoctorFollowUp",
  FACE_SCAN_REMINDER = "FaceScanReminder",
  DOCTOR_CONSULT = "DoctorConsult",
  PRESTIGE_UNLOCK = "PrestigeUnlock",
  EDUCATION_AND_ENGAGEMENT = "EducationAndEngagement",
  ONBOARDING_COMPLETE = "OnboardingComplete",
  ACHIEVER_UNLOCK = "AchieverUnlock",
  PATHOLOGY_ORDER_UTILIZED = "PathologyOrderUtilized",
  PATHOLOGY_ORDER_COMPLETE = "PathologyOrderCompleted",
}

export interface Message {
  id: string;
  title: string;
  subTitle: string;
  message: string;
  date: string;
  notificationStatus: MessageStatus;
  messageType: MessageType;
  meta: Array<{
    key: string;
    value: string;
  }>;
  detailType: MessageDetailType;
}

export interface MembershipCardConfig {
  friendlyName?: string;
  memberFullName: string;
  status: string;
  productCode: string;
  beneficiaryCode: string;
  membershipNumber: string;
  product: Product;
  pharmacySwitchCode?: string;
}

export interface MemberProfile {
  firstName: string;
  consultation: {
    consultationResponses: GetMemberConsultationsItem[];
    consultationRates: MemberProductPaymentRatesItem[];
  };
  memberServiceOfferingUsageCount: string;
  healthProfile: {
    lastUpdatedAt: string;
    healthScoreValue: number;
  };
  benefitCount: number;
  dependentCount: number;
  membership: {
    id: string;
    fullName: string;
    beneficiaryCode: string;
    productCode: string;
    status: MembershipStatus;
    productFriendlyName: string;
    membershipNumber: string;
    dependentCount: number;
  };
  product: Product;
}

export interface Product {
  id?: string;
  name: string;
  code: string;
  brochureUri: string;
  imageUri: string;
  productProvider?: ProductProvider;
  seriesName: string;
  startDate: string;
  pharmacySwitchCode?: string;
  provider: ProductProvider;
  administrator: ProductAdministrator;
  contactDetails?: {
    callCenterNumber: string;
    callCenterEmail: string | null;
    counselingNumber: string;
    whatsappNumber: string;
  };
  benefitCategory?: ProductBenefitCategory[];
  productPremiums?: ProductPremium[];
  description?: string | null;
  termsAndConditionsUri?: string | null;
  policyScheduleUri?: string | null;
  policyWordingUri?: string | null;
  allowDependents?: boolean;
  medicalServiceProvider?: MedicalServiceProviderType;
  underwriter?: ProductUnderwriter;
  friendlyName?: string;
  supportedActions?: {
    initiateBinahScan: boolean;
  };
}

export interface Member {
  isPrimaryCard: boolean;
  membershipDetails: {
    id: string;
    type: MembershipType;
    number: string;
    status: MembershipStatus;
    beneficiaryCode: string;
  };
  memberDetails: {
    id: string;
    memberFullName: string;
    identityDocumentType: IdentityDocumentType | null;
    identityDocumentValue: string | null;
    dateOfBirth: string;
  };
}

export enum MembershipDependentIdentityDocumentType {
  ID_NUMBER = "ID_NUMBER",
  PASSPORT_NUMBER = "PASSPORT_NUMBER",
  UNUID = "UNUID",
}
export interface MembershipDependent {
  firstName: string;
  lastName: string;
  identityDocumentType?: IdentityDocumentType | null;
  identityDocumentValue?: string | null;
  sexAtBirth?: string;
  dateOfBirth?: string;
  identityDocumentCountryOfIssue?: string;
  status?: string;
}

export enum MembershipDependentStatus {
  ACTIVE = "active",
  DELETE = "deleted",
}

interface RequestBaseOptions {
  signal: AbortSignal;
}

export interface UserLoginRequestData {
  phoneNumber: { globalSubscriberNumber: string; countryCode: string };
  password: string;
  identityDocumentType: IdentityDocumentType | string;
}

export interface ThirdPartyUserLoginRequestData {
  phoneNumber: { globalSubscriberNumber: string; countryCode: string };
  firstName: string;
  lastName: string;
}

export interface queryDetailsData {
  firstName: string;
  lastName: string;
  phoneNumber: { globalSubscriberNumber: string; countryCode: string };
  emailAddress: string;
  feedbackMessage: string;
  querySourceURI: string;
}

export interface UserLoginResponseData {
  mobilePhone: string;
  cognitoUserId: string;
  sessionId: string;
  isError: boolean;
  message: string;
  isSuccess?: boolean;
  challenges?: AuthenticationResult[];
}

export interface CollectTermsAcceptanceContract {
  ContractType: string;
  Version: number;
  Description: string;
  FileUri: string;
}

export type HealthCheckQuestionnaireData = {
  sexAtBirth: { value: string };
  weight: { value: string };
  height: { unit: string; value: number };
  dateOfBirth: { value: string };
  smokingStatus: { value: "Yes" | "No" };
};

export interface GetMemberErrorResponseData {
  error: {
    code: number;
    message: string;
    correlationId: string;
  };
}

export interface Doctor {
  professionalDisplayName: string;
  facilityName: string;
  facilityID: string;
  professionalID: string;
  profFacilityID: string;
  doctorType: string;
  practiceNumber: string;
  personalPracticeNumber: string;
  hpcsaNumber: string;
  imageURL: string;
  professionalTitle: string;
  professionalName: string;
  professionalSurname: string;
  professionalGender: string;
  professionalDisciplineCode: string;
  branchCode: string;
  facilityDesc: string;
  facilityEmail: string;
  facilityTel: string;
  facilityURL: string;
  latitude: string;
  longitude: string;
  address: string;
  province: string;
  town: string;
}

export interface PathologyFacility {
  facilityName: string;
  facilityAddress: string;
  facilityContactNumber: string;
  facilityLatitude: number;
  facilityLongitude: number;
  province: string;
  town: string;
  openingTimes: {
    day: string;
    hours: string;
  }[];
}
export interface PathologyTest {
  testName: string;
  testAmount: number;
  testCode: string;
}

export interface PathologyPanel {
  panelId: string;
  panelName: string;
  amountTotal: number;
  amountTotalFormatted: string;
  unuHandlingFeeAmountFormatted: string;
  unuTransactionalFeeAmountFormatted: string;
  panelTestsAmountFormatted: string;
  panelChargeCode: PathologyPanelChargeCode;
  panelDescription: string;
  status: Status;
  orderIndex: number;
  created: string;
  panelPathologyTests: PathologyTest[];
}

export enum PathologyOrderStatus {
  CONFIRMED = "Confirmed",
  PENDING = "Pending",
  COMPLETED = "Completed",
  CANCELLED = "Cancelled",
  CREATED = "Created",
  FAILED = "Failed",
  TEST_SUBMITTED = "TestSubmitted",
}

export enum PathologyPaymentStatus {
  NONE = "NONE",
  CREATED = "CREATED",
  PAYMENT_REQUESTED = "PAYMENT_REQUESTED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_SUCCEEDED = "PAYMENT_SUCCEEDED",
  PAYMENT_REFUNDED = "PAYMENT_REFUNDED",
  PAYMENT_VOIDED = "PAYMENT_VOIDED",
  COMPLETED = "COMPLETED",
}

export interface PathologyPaymentReceipt {
  paymentSuccess: boolean;
  status: PathologyPaymentStatus;
  paymentUtcDateTime: string;
  paymentAmount: number;
  currencyCode: string;
  transactionReferenceNumber: string;
  unuMerchantId: string;
  cardNumber: string;
}

export interface PathologyOrder {
  orderId: string;
  panelName: string;
  panelChargeCode: PathologyPanelChargeCode;
  orderNumber: string;
  orderPrice: number;
  orderUnuFeeAmount: number;
  amountTotalFormatted: string;
  status: PathologyOrderStatus;
  created: string;
  orderPathologyTests: PathologyTest[];
}

export interface GetPathologyOrdersSuccessResponse {
  skipCount: number;
  listLength: number;
  total: number;
  orders: PathologyOrder[];
}

export enum Status {
  ACTIVE = "active",
  INACTIVE = "inactive",
}

export enum DirectToDoctorPrimarySymptomOption {
  SKIN_CONDITIONS = "SKIN_CONDITIONS",
  EYE_INFECTIONS = "EYE_INFECTIONS",
  BLADDER_INFECTION = "BLADDER_INFECTION",
  COLD_FLU = "COLD_FLU",
  VOMITING_DIARRHEA = "VOMITING_DIARRHEA",
  HEADACHE = "HEADACHE",
  BACK_PAIN = "BACK_PAIN",
  CONTRACEPTION = "CONTRACEPTION",
  GENITAL_INFECTIONS = "GENITAL_INFECTIONS",
  GENERAL_CONDITIONS = "GENERAL_CONDITIONS",
}

export interface DirectToDoctorPrimarySymptom {
  id: string;
  name: DirectToDoctorPrimarySymptomOption;
  friendlyName: string;
  status: Status;
}

export enum PathologyPanelChargeCode {
  CD4_VIRAL_LOAD = "PATH_VIRAL_LOAD",
  HBA1C = "PATH_HBA1C",
  ANTENATAL = "PATH_ANTENATAL",
  STD_SCREENING_EXCL_HIV = "PATH_STD_EXCL_HIV",
  SYPHILIS = "PATH_SYPHILIS",
  STD_PCR = "PATH_STD_PCR",
  HIV_SCREENING = "PATH_HIV_SCREENING",
  HEPATITIS_B = "PATH_HEPATITIS_B",
  MALE_OVER_45 = "PATH_MALE_OVER_45",
  ANAEMIA = "PATH_ANAEMIA",
  MALE_UNDER_45 = "PATH_MALE_UNDER_45",
  HIV_MONITORING = "PATH_HIV_MONITORING",
  STD_SCREENING_INCL_HIV = "PATH_STD_INCL_HIV",
  FEMALE = "PATH_FEMALE",
  DIABETES_MONITORING = "PATH_DIABETES",
  BLOOD_PRESSURE_MONITORING = "PATH_BP",
  CHOLESTEROL_MONITORING = "PATH_CHOLESTEROL",
}

export enum MarketingChannelOptions {
  UNU_ADVERTISEMENT = "UNU_ADVERTISEMENT",
  WORD_OF_MOUTH = "WORD_OF_MOUTH",
  STANDARD_BANK = "STANDARD_BANK",
  ACKERMANS = "ACKERMANS",
  OTHER = "OTHER",
}
export interface MarketingChannel {
  id: string;
  name: MarketingChannelOptions;
  friendlyName: string;
  status: Status;
}

export interface MarketingChannelStore {
  id: string;
  description: string;
  location: string;
  city: string;
  province: string;
}

interface GetMarketingChannelsStores {
  id: string;
  province: string;
}

export interface SubmitUserDetailsRequest {
  firstName: string | null;
  lastName: string | null;
  marketingChannel?: {
    id: string;
    metaData: {
      marketingChannelDescription?: string;
      otherFieldReason?: string;
      marketingChannelLocationId?: string;
      agentCode?: string;
    };
  };
  dateOfBirth?: string | null;
  sexAtBirth?: string | null;
}

export enum ServiceOfferingUsageEnum {
  ACTIVE = "ACTIVE",
  AVAILABLE_BETWEEN = "AVAILABLE_BETWEEN",
  EXPIRED = "EXPIRED",
  INACTIVE = "INACTIVE",
  NEXT_AVAILABLE = "NEXT_AVAILABLE",
  USED = "USED",
}
export interface ServiceOfferingUsage {
  availabilityStatus: ServiceOfferingUsageEnum;
  availableDateRange: {
    startDate: string;
    endDate: string;
  };
  scanCompletionDate: string | null;
  order: number;
  unlimited: boolean;
}

export interface GetServiceOfferingUsageSuccessResponseData {
  body: ServiceOfferingUsage[];
  total: number;
}

export interface GetMemberSuccessResponseData {
  firstName: string;
  lastName: string;
  identityDocumentType: string;
  identityDocumentValue: string;
  mobileNumber: string;
  isOnboarded: boolean;
  isInformationConsentComplete: boolean;
  organizationId: string;
  organizationName: string;
  organizationImageUri: string;
  memberId: string;
  cognitoUserId: string;
  isError: boolean;
  message: string | null;
  isRetailMember?: boolean | null;
  emailAddress?: string;
}

interface GetMemberSuccessResponse {
  data: GetMemberSuccessResponseData;
}

interface GetMemberContactDetailsErrorResponseData {
  error: {
    code: number;
    message: string;
    correlationId: string;
  };
}

export interface GetMemberContactDetailsSuccessResponseData {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  emailAddress: string;
  homeAddress: {
    address1: string | null;
    address2: string | null;
    city: string | null;
    province: string | null;
    postalCode: string | null;
  };
  isError: boolean;
  message: string | null;
}

interface GetMemberContactDetailsSuccessResponse {
  data: GetMemberContactDetailsSuccessResponseData;
}

export interface LoginOTPRequestData {
  sessionID: string;
  cognitoUserId: string;
  mfaCode: string;
  phoneNumber: { globalSubscriberNumber: string; countryCode: string };
}

export interface AuthenticationResult {
  accessToken: string;
  expiresIn: number;
  idToken: string;
  newDeviceMetadata: null;
  refreshToken: string;
  tokenType: string;
}

export interface LoginOTPResponseData {
  sessionID: string;
  challengeName: string;
  authenticationResult: AuthenticationResult;
  challengeParameters: object;
  clientMetadata: object;
}

interface RefreshAccessTokenSuccessResponseData {
  authenticationResult: AuthenticationResult;
  isError: boolean;
  message: string | null;
}

export interface DecodedToken {
  sub: string;
  iss: string;
  phone_number_verified: boolean;
  ["cognito:username"]: string;
  given_name: string;
  origin_jti: string;
  aud: string;
  event_id: string;
  token_use: string;
  auth_time: number;
  phone_number: string;
  exp: number;
  iat: number;
  jti: string;
}

export interface IdentificationNumber {
  identificationType: string;
  identificationValue: string;
}

export type FaceScanPersonalInformationNullableBoolean = boolean | null;
export type FaceScanPersonalInformationGender = "male" | "female" | null;
export type FaceScanPersonalInformationDiabetes = "yes" | "no" | null;
export interface FaceScanPersonalInformation {
  identifier: string;
  personName: string;
  emailAddress: string;
  ageInYears: number;
  heightInCm: number;
  weightInKg: number;
  gender: FaceScanPersonalInformationGender;
  diabetes: FaceScanPersonalInformationDiabetes;
  smoking: FaceScanPersonalInformationNullableBoolean;
  bloodPressureMedication: FaceScanPersonalInformationNullableBoolean;
}

const baseURI = String(import.meta.env.VITE_APP_UNU_HEALTH_API_BASE_URI) || "";
const notificationsBaseURI =
  String(import.meta.env.VITE_APP_UNU_HEALTH_NOTIFICATIONS_API_BASE_URI) || "";
const chronicCareBaseURI =
  String(import.meta.env.VITE_APP_UNU_HEALTH_CHRONIC_CARE_API_BASE_URI) || "";

interface HealthProfileErrorResponseData {
  error: {
    code: number;
    message: string;
    correlationId: string;
  };
}

interface HealthProfileSuccessResponse {
  data: HealthProfileSuccessResponseData;
}

export interface HealthProfileSuccessResponseData {
  healthScoreValue: string | null;
  items: {
    isVerified: boolean;
    questionId: string;
    questionType: string;
    data: string;
  }[];
  lastUpdatedAt: string;
  percentageComplete: number;
}

export enum HealthProfileItemDataSource {
  USER_SUBMITTED = "User Submitted",
  CALCULATED = "Calculated",
  HEALTH_CLOUD = "Health Cloud",
  HEALTH_CLOUD_ABBY_STATION = "Health Cloud - Abby Station",
  HEALTH_CLOUD_WEARABLE_DEVICE = "Health Cloud - Wearable Device",
  HEALTH_CLOUD_CLAIMS_DIAGNOSTIC_DISPENSARY = "Health Cloud - Claims, Diagnostic, Dispensary",
  HEALTH_CLOUD_BIOMETRICS_LIFESTYLE_PHYSICAL_WELLNESS = "Health Cloud - Biometrics, Lifestyle, Physical, Wellness",
  HEALTH_CLOUD_OTHER = "Health Cloud - Other",
  UNU_HEALTH = "UNU-HEALTH",
  BINAH = "BINAH",
}

interface ReportIssueErrorResponseData {
  error: {
    code: number;
    message: string;
    correlationId: string;
  };
}

export interface ReportIssueSuccessResponseData {
  isError: boolean;
  message: string | null;
}

interface ReportIssueSuccessResponse {
  data: ReportIssueSuccessResponseData;
}

export enum ThirdPartyServices {
  COU_DOCTOR = "COU_DOCTOR",
  COU_NURSE = "COU_NURSE",
  COU_LABORATORY = "COU_LABORATORY",
  COU_PHARMACY = "COU_PHARMACY",
  COU_CLINIC = "COU_CLINIC",
}

export enum MemberConsultationOutcome {
  TRIAGE_STARTED = "TRIAGE_STARTED",
  SESSION_STARTED = "SESSION_STARTED",
  PAYMENT_REQUESTED = "PAYMENT_REQUESTED",
  PAYMENT_SUCCEEDED = "PAYMENT_SUCCEEDED",
  DIGITAL_CONSULT_STARTED = "DIGITAL_CONSULT_STARTED",
  NO_REFUND = "NO_REFUND",
  TRIAGE_ENDED = "TRIAGE_ENDED",
  PAYMENT_FAILED = "PAYMENT_FAILED",
  DIGITAL_CONSULT_ENDED = "DIGITAL_CONSULT_ENDED",
  INPERSON_CONSULT = "INPERSON_CONSULT",
  EMERGENCY_CONSULT = "EMERGENCY_CONSULT",
  REFUND_REQUESTED = "REFUND_REQUESTED",
}

export const VALID_CONSULTATION_OUTCOMES = [
  MemberConsultationOutcome.DIGITAL_CONSULT_STARTED,
  MemberConsultationOutcome.TRIAGE_STARTED,
  MemberConsultationOutcome.SESSION_STARTED,
  MemberConsultationOutcome.TRIAGE_ENDED,
  MemberConsultationOutcome.PAYMENT_SUCCEEDED,
  MemberConsultationOutcome.NO_REFUND,
];

export enum MemberConsultationType {
  SYMPTOM_ASSESSMENT = "SYMPTOM_ASSESSMENT",
  DIGITAL_COU_NURSE = "DIGITAL_COU_NURSE",
  DIGITAL_DOCTOR_CONSULT = "DIGITAL_DOCTOR_CONSULT",
}

export enum HealthCheckProvider {
  INTERCARE = "INTERCARE",
  DEFAULT = "DEFAULT",
}

interface AxiosRequestConfigWithCustomProps extends AxiosRequestConfig {
  isAttemptingToGetNewAccessToken: boolean;
}

const paths = pathBuilder({
  baseURI: baseURI,
  notificationsBaseURI: notificationsBaseURI,
  chronicCareBaseURI: chronicCareBaseURI,
});

const noCacheHeaders = {
  "Cache-Control": "no-cache",
  Pragma: "no-cache",
  Expires: "0",
};

const axiosInstance = axios.create({
  baseURL: baseURI,
});

axiosInstance.interceptors.request.use(function (config: any) {
  config.headers["Accept-Language"] = i18n.resolvedLanguage;
  const authenticationResult: AuthenticationResult = retrieveValue(
    "authenticationResult"
  );

  if (authenticationResult && !config.url.includes("liberty/capture-vitals")) {
    config.headers.Authorization = "Bearer " + authenticationResult.idToken;
  }

  return config;
});

function responseInterceptorForSuccessfulResponses(response: any) {
  if (response.data?.isError === true || response.data?.isSuccess === false) {
    return Promise.reject(response);
  }
  return response;
}

function captureAnalyticsEventsForErrors(status: number) {
  switch (status) {
    case 404:
      trackErrorEvent({
        errorMessage: AnalyticsErrorEvent.PAGE_NOT_FOUND,
      });
      break;
    case 500:
      trackErrorEvent({
        errorMessage: AnalyticsErrorEvent.INTERNAL_SERVER_ERROR,
      });
      break;
    case 503:
      trackErrorEvent({
        errorMessage: AnalyticsErrorEvent.SERVICE_UNAVAILABLE,
      });
      break;
    case 408:
      trackErrorEvent({
        errorMessage: AnalyticsErrorEvent.REQUEST_TIMED_OUT,
      });
      break;
    default:
      trackErrorEvent({
        errorMessage: `Unknown error: ${status}`,
      });
  }
}

async function responseInterceptorForUnsuccessfulResponses(
  error: AxiosError<{ error: { message: string } }>
) {
  const currentRequest = error.config as AxiosRequestConfigWithCustomProps;
  const currentResponseStatus = error.response?.status || 500;
  const currentResponseHeaders = error.response?.headers || {};

  const modifiedResponse = {
    status: currentResponseStatus,
    data: error.response?.data?.error || {
      message: "",
    },
  };

  const isTheCurrentResponseForARefreshToken = currentRequest.url
    ? currentRequest.url.indexOf(paths.getNewAccessToken) > -1
    : false;

  const isAuthenticationTokenExpired =
    (currentResponseStatus === 401 &&
      currentResponseHeaders["content-length"] === "0") ||
    false;

  let hasTheRefreshTokenResponseFailed = false;

  const isRefreshTokenExpired =
    modifiedResponse.data.message === "Refresh Token has expired" || false;

  const shouldRefreshTokenBeRequested =
    isAuthenticationTokenExpired &&
    !isTheCurrentResponseForARefreshToken &&
    !isRefreshTokenExpired;

  if (shouldRefreshTokenBeRequested) {
    const getNewAccessTokenResponse = await getNewAccessToken();

    if (getNewAccessTokenResponse) {
      return axiosInstance(currentRequest);
    } else {
      hasTheRefreshTokenResponseFailed = true;
    }
  }

  if (isRefreshTokenExpired || hasTheRefreshTokenResponseFailed) {
    window.location.href = `/auth-session-error?redirectedFrom=${encodeURIComponent(
      window.location.pathname + window.location.search
    )}`;
    return;
  }

  if (currentResponseStatus === 500) {
    modifiedResponse.data.message = "unknown_error";
  }

  if (error.response?.status === 500) {
    const errorData = {
      status: error.response?.status,
      message: error.message || "",
      data: error.response?.data?.error || "",
    };

    captureException(
      `Error experienced on ${currentRequest.url}: ${JSON.stringify(errorData)}`
    );

    captureAnalyticsEventsForErrors(error.response?.status);
  }
  return Promise.reject(modifiedResponse);
}

axiosInstance.interceptors.response.use(
  responseInterceptorForSuccessfulResponses,
  responseInterceptorForUnsuccessfulResponses
);

async function getNewAccessToken(): Promise<RefreshAccessTokenSuccessResponseData> {
  const authenticationResult: AuthenticationResult = retrieveValue(
    "authenticationResult"
  );
  const currentUser = retrieveValue("currentUser") || {
    cognitoUserId: "",
  };

  return axiosInstance
    .post(paths.getNewAccessToken, {
      refreshToken: authenticationResult.refreshToken,
      cognitoUserId: currentUser.cognitoUserId,
    })
    .then((res) => {
      persistValue("authenticationResult", res.data.authenticationResult);
      return res.data;
    })
    .catch(() => {
      return null;
    });
}

interface ApiHookResponse {
  data: any;
  isFetching: boolean;
  error: any;
}

export function useHealthCheck(): ApiHookResponse {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsFetching(true);
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(paths.health, {
          headers: {
            ...noCacheHeaders,
          },
        });

        setData(response.data);
      } catch (e: any) {
        setError(e);
      }
      setIsFetching(false);
    };

    fetchData();
  }, []);

  return {
    isFetching,
    data,
    error,
  };
}

export function getHealthScoreRating(healthScore: number) {
  if (healthScore > 66) {
    return "GOOD";
  } else if (healthScore <= 66 && healthScore > 33) {
    return "AVERAGE";
  } else {
    return "POOR";
  }
}

function maybeAddAuthenticationResponseData(data: any) {
  const authenticationResult =
    (data?.challenges || []).find(
      (challenge: AuthenticationResult) => challenge
    ) || data?.authenticationResult;

  if (authenticationResult) {
    const decodedData = jwtDecode<DecodedToken>(authenticationResult?.idToken);

    persistValue("authenticationResult", authenticationResult);

    data = {
      id: decodedData["cognito:username"],
      authenticationResult: authenticationResult,
      ...data,
    };
  }

  return data;
}

export function loginUser(
  userLoginRequestData: UserLoginRequestData
): Promise<UserLoginResponseData> {
  return axiosInstance({
    method: "post",
    url: paths.login,
    headers: {
      ...noCacheHeaders,
    },
    data: {
      mobilePhone: formatPhoneNumber(
        userLoginRequestData.phoneNumber.globalSubscriberNumber,
        userLoginRequestData.phoneNumber.countryCode
      ),
      password: userLoginRequestData.password,
      identityDocumentType: userLoginRequestData.identityDocumentType,
    },
  })
    .then((res) => {
      return maybeAddAuthenticationResponseData(res.data);
    })
    .catch((error) => {
      return Promise.reject(error?.data || error);
    });
}

export function loginThirdPartyUser(
  thirdPartyUserLoginRequestData: ThirdPartyUserLoginRequestData
): Promise<UserLoginResponseData> {
  return axiosInstance({
    method: "post",
    url: paths.loginCou,
    headers: {
      ...noCacheHeaders,
    },
    data: {
      mobilePhone: formatPhoneNumber(
        thirdPartyUserLoginRequestData.phoneNumber.globalSubscriberNumber,
        thirdPartyUserLoginRequestData.phoneNumber.countryCode
      ),
      firstName: thirdPartyUserLoginRequestData.firstName,
      lastName: thirdPartyUserLoginRequestData.lastName,
    },
  })
    .then((res) => {
      return maybeAddAuthenticationResponseData(res.data);
    })
    .catch((error) => {
      return Promise.reject(error?.data || error);
    });
}

export function loginOTP(
  loginOTPRequestData: LoginOTPRequestData
): Promise<LoginOTPResponseData> {
  return axiosInstance({
    method: "post",
    url: paths.loginOtp,
    headers: {
      ...noCacheHeaders,
    },
    data: {
      sessionID: loginOTPRequestData.sessionID,
      cognitoUserId: loginOTPRequestData.cognitoUserId,
      mfaCode: loginOTPRequestData.mfaCode,
      mobilePhone: formatPhoneNumber(
        loginOTPRequestData.phoneNumber.globalSubscriberNumber,
        loginOTPRequestData.phoneNumber.countryCode
      ),
    },
  })
    .then((res) => {
      return maybeAddAuthenticationResponseData(res.data);
    })
    .catch((error) => {
      if (!error.data) {
        error.data = {
          message: "unknown_error",
        };
      }

      return Promise.reject(error.data);
    });
}

export function getContract(): Promise<any> {
  return axiosInstance({
    method: "get",
    url: paths.getContractFile,
    headers: {
      ...noCacheHeaders,
    },
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

interface ContractAgreement {
  contractType: string;
  version: string;
}

export function submitContractAgreement(
  contract: ContractAgreement
): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.submitContractAgreement
      .replace("{ContractType}", contract.contractType)
      .replace("{Version}", contract.version),
    headers: {
      ...noCacheHeaders,
    },
    data: {
      isAgreed: true,
      agreedOn: new Date().toISOString(),
    },
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function submitMultipleContractAgreements(
  contracts: ContractAgreement[]
): Promise<any> {
  return Promise.all(
    contracts.map((contractAgreementPayload) => {
      return submitContractAgreement(contractAgreementPayload);
    })
  );
}

export function getMember(): Promise<
  GetMemberErrorResponseData | GetMemberSuccessResponseData
> {
  return axiosInstance({
    method: "get",
    url: paths.getMember,
    headers: {
      ...noCacheHeaders,
    },
  })
    .then((res: GetMemberSuccessResponse) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      if (
        error.data.message === "Request failed with status code 400" ||
        error.data.message === "unknown_error"
      ) {
        error.data.message = "OTP number entered incorrectly";
      }
      return Promise.reject(error.data);
    });
}

export function getMemberProfile(
  options?: RequestBaseOptions
): Promise<MemberProfile> {
  return axiosInstance({
    method: "get",
    url: paths.getMemberProfile,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res) => {
      return Promise.resolve(res.data as MemberProfile);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export interface GetMemberRequiredActionsItem {
  name: string;
  description: null | string;
  data: null | string;
}
interface GetMemberRequiredActionsSuccessResponseData {
  data: GetMemberRequiredActionsItem[];
}

export enum GetMemberProductPaymentRatesStatus {
  ACTIVE = "ACTIVE",
  EXPIRED = "EXPIRED",
}

export enum MemberConsultationStatus {
  ACTIVE = "active",
  ARCHIVED = "archived",
}

export enum MemberProductPaymentRatesChargeCode {
  IN_PERSON_DOCTOR_CONSULT = "IN_PERSON_DOCTOR_CONSULT",
  ONLINE_DOCTOR_CONSULTATION = "ONLINE_DOCTOR_CONSULTATION",
  CHAT_TO_A_NURSE = "CHAT_TO_A_NURSE",
  DIGITAL_DOCTOR_CONSULT = "DIGITAL_DOCTOR_CONSULT",
  REMOTE_DOCTOR_CONSULTATIONS = "REMOTE_DOCTOR_CONSULTATIONS",
  CLINIC = "CLINIC",
  LABORATORY = "LABORATORY",
  PHARMACY = "PHARMACY",
  NURSE_CHAT = "NURSE_CHAT",
}

export interface GetMemberConsultationsItem {
  consultationId: any;
  memberId: string;
  mainMemberId: string;
  isDependant: boolean;
  outcome: MemberConsultationOutcome;
  redirectUrl: string;
  chargeAmountFormatted: string | null;
  chargeCode: MemberProductPaymentRatesChargeCode | null;
  consultationTypeName: string;
  serviceProviderName: string;
  startedOn: string;
  status: MemberConsultationStatus;
}

interface USSDConfiguration {
  ussd: string;
  menuOption: number;
}

export interface MemberProductPaymentRatesItem {
  chargeCode: MemberProductPaymentRatesChargeCode;
  amount: string;
  amountFormatted: string;
  ussdConfiguration?: USSDConfiguration;
}

interface GetMemberConsultationsSuccessResponseData {
  data: GetMemberConsultationsItem[];
}

interface MemberProductPaymentRatesSuccessResponseData {
  data: MemberProductPaymentRatesItem[];
}

interface GetMemberConsultationsOutcomeSuccessResponseData {
  data: GetMemberConsultationsItem;
}

export function getMemberRequiredActions(): Promise<
  GetMemberRequiredActionsItem[]
> {
  return axiosInstance({
    method: "get",
    url: paths.getMemberRequiredActions,
    headers: {
      ...noCacheHeaders,
    },
  })
    .then((res: GetMemberRequiredActionsSuccessResponseData) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function getMemberConsultations(
  status: GetMemberProductPaymentRatesStatus = GetMemberProductPaymentRatesStatus.ACTIVE,
  options?: RequestBaseOptions
): Promise<
  GetMemberConsultationsItem[] | GetMemberConsultationsSuccessResponseData
> {
  const urlWithParams = new URL(paths.getMemberConsultations);
  urlWithParams.searchParams.append("status", status);

  return axiosInstance({
    method: "get",
    url: urlWithParams.href,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: GetMemberConsultationsSuccessResponseData) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function getMemberProductPaymentRates(
  status: GetMemberProductPaymentRatesStatus = GetMemberProductPaymentRatesStatus.ACTIVE,
  options?: RequestBaseOptions
): Promise<
  MemberProductPaymentRatesItem[] | MemberProductPaymentRatesSuccessResponseData
> {
  const urlWithParams = new URL(paths.getMemberProductPaymentRates);
  urlWithParams.searchParams.append("status", status);

  return axiosInstance({
    method: "get",
    url: urlWithParams.href,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: MemberProductPaymentRatesSuccessResponseData) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function getMemberConsultationOutcome(
  consultationId: string,
  options?: RequestBaseOptions
): Promise<
  GetMemberConsultationsOutcomeSuccessResponseData | GetMemberConsultationsItem
> {
  return axiosInstance({
    method: "get",
    url: paths.getMemberConsultationOutcome.replace(
      "{ConsultationId}",
      consultationId
    ),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: GetMemberConsultationsOutcomeSuccessResponseData) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function submitMemberAcknowledgeEvent(eventType: string): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.submitMemberAcknowledgeEvent.replace("{EventType}", eventType),
    headers: {
      ...noCacheHeaders,
    },
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function startMemberConsultation(
  consultationType: MemberConsultationType,
  memberId: string,
  primarySymptomIds?: string[],
  isPromotional?: boolean
): Promise<any> {
  const data = {
    consultationType: consultationType,
    memberId: memberId,
    ...(primarySymptomIds &&
      primarySymptomIds?.length > 0 && {
        primarySymptomIds: primarySymptomIds,
      }),
    ...(isPromotional !== null && {
      isPromotional: isPromotional,
    }),
  };
  return axiosInstance({
    method: "post",
    url: paths.startMemberConsultation,
    headers: {
      ...noCacheHeaders,
    },
    data: data,
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function submitRating(data: UserRating): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.submitRating,
    headers: {
      ...noCacheHeaders,
    },
    data: {
      serviceType: data.serviceType,
      ratedServiceId: data.ratedServiceId,
      rating: data.rating,
      comment: data.comment,
    },
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function getMemberContactDetails(
  options?: RequestBaseOptions
): Promise<
  | GetMemberContactDetailsErrorResponseData
  | GetMemberContactDetailsSuccessResponseData
> {
  return axiosInstance({
    method: "get",
    url: paths.getMemberContactDetails,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: GetMemberContactDetailsSuccessResponse) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function getHealthProfile(
  options?: RequestBaseOptions
): Promise<HealthProfileErrorResponseData | HealthProfileSuccessResponseData> {
  return axiosInstance({
    method: "get",
    url: paths.healthProfile,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: HealthProfileSuccessResponse) => {
      return res.data;
    })
    .then((data: HealthProfileSuccessResponseData) => {
      if ("items" in data) {
        data.items = data.items.map((item) => {
          let mutatedData = null;
          try {
            mutatedData = JSON.stringify(
              transformObjectKeysToCamelCase(JSON.parse(item.data))
            );
          } catch {}
          return {
            ...item,
            data: mutatedData ? mutatedData : item.data,
          };
        });
      }
      return data;
    })
    .then((data: any) => {
      return Promise.resolve(data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

interface MembershipsErrorResponseData {
  error: {
    code: number;
    message: string;
    correlationId: string;
  };
}

export enum MedicalServiceProviderType {
  WHATSAPP = "WhatsApp",
  CALL_ME_BACK = "PleaseCallMe",
}

export interface Membership {
  productDetails: Product;
  members: Member[];
}
export interface MembershipsSuccessResponseData {
  memberships: Membership[];
}

interface MembershipsSuccessResponse {
  data: MembershipsSuccessResponseData;
}

function addHardcodedUnderwriter(membership: Membership): Membership {
  const _productDetails = membership.productDetails;

  _productDetails.underwriter = _productDetails.underwriter
    ? _productDetails.underwriter
    : {
        id: "",
        name: "",
        code: "",
        imageUri: "",
      };

  if (
    membership.productDetails.code === "PRIST" ||
    membership.productDetails.code === "GLDHR"
  ) {
    _productDetails.underwriter = {
      id: "f350c1f0-c8f8-46d6-8aae-519b32d8e187",
      name: "Genric",
      code: "",
      imageUri: "",
    };
  }

  return {
    ...membership,
    productDetails: {
      ..._productDetails,
    },
  };
}

function addFriendlyName(product: Product) {
  return {
    ...product,
    friendlyName: product.friendlyName || product.name,
  };
}

export function getMembershipSupportedServices(
  membershipOrProduct: Membership | Product
): ProductAdministratorSupportedService[] {
  if (membershipOrProduct && Object.keys(membershipOrProduct)?.length > 0) {
    if ("administrator" in membershipOrProduct) {
      return membershipOrProduct.administrator?.supportedServices || [];
    }

    return (
      membershipOrProduct?.productDetails?.administrator?.supportedServices ||
      []
    );
  }

  return [];
}

export function getMembershipCallCentreService(
  membershipOrProduct: Membership | Product
): ProductAdministratorSupportedService | null {
  return (
    getMembershipSupportedServices(membershipOrProduct).find(
      (supportedService) => {
        return (
          supportedService.type ===
          ProductAdministratorSupportedServiceType.CALL_CENTRE
        );
      }
    ) || null
  );
}

export function getMembershipCallCentreServicePhoneChannel(
  membershipOrProduct: Membership | Product
): ProductAdministratorSupportedServiceChannel | null {
  return (
    getMembershipCallCentreService(membershipOrProduct)?.channels.find(
      (channel) => {
        return (
          channel.type === ProductAdministratorSupportedServiceChannelType.PHONE
        );
      }
    ) || null
  );
}

export function getMembershipMedicalAssistanceService(
  membershipOrProduct: Membership | Product
): ProductAdministratorSupportedService | null {
  return (
    getMembershipSupportedServices(membershipOrProduct).find(
      (supportedService) => {
        return (
          supportedService.type ===
          ProductAdministratorSupportedServiceType.MEDICAL_ASSISTANCE
        );
      }
    ) || null
  );
}

export function getMembershipMedicalAssistanceServiceWhatsAppChannel(
  membershipOrProduct: Membership | Product
): ProductAdministratorSupportedServiceChannel | null {
  return (
    getMembershipMedicalAssistanceService(membershipOrProduct)?.channels.find(
      (channel) => {
        return (
          channel.type ===
          ProductAdministratorSupportedServiceChannelType.WHATSAPP
        );
      }
    ) || null
  );
}

export function getMembershipCounsellingAssistanceService(
  membershipOrProduct: Membership | Product
): ProductAdministratorSupportedService | null {
  return (
    getMembershipSupportedServices(membershipOrProduct).find(
      (supportedService) => {
        return (
          supportedService.type ===
          ProductAdministratorSupportedServiceType.COUNSELLING_ASSISTANCE
        );
      }
    ) || null
  );
}

export function getMembershipCounsellingAssistanceServicePhoneChannel(
  membershipOrProduct: Membership | Product
): ProductAdministratorSupportedServiceChannel | null {
  if (!membershipOrProduct) {
    return null;
  }
  return (
    getMembershipCounsellingAssistanceService(
      membershipOrProduct
    )?.channels.find((channel) => {
      return (
        channel.type === ProductAdministratorSupportedServiceChannelType.PHONE
      );
    }) || null
  );
}

export function getMemberships(
  options?: RequestBaseOptions
): Promise<MembershipsErrorResponseData | MembershipsSuccessResponseData> {
  return axiosInstance({
    method: "get",
    url: paths.getMemberships,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: MembershipsSuccessResponse) => {
      const memberships = res.data.memberships.map((membership) => {
        membership = addHardcodedUnderwriter(membership);
        return membership;
      });

      return Promise.resolve({ memberships: memberships });
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function submitHealthRecordEvent(
  type: SubmitHealthRecordEventType,
  selectedOption?: ChatBotOption
): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.healthRecordEvent.replace("{type}", type),
    headers: {
      ...noCacheHeaders,
    },
    data: {
      selectedOption: selectedOption,
    },
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function isPleaseCallMeServiceAvailable(): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.healthProfileHaveANurseCallMeBackCheck,
    headers: {
      ...noCacheHeaders,
    },
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function haveANurseCallMeBack(callBackData: {
  assistanceMembershipId: string | null;
}): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.healthRecordHaveNurseCallMeBackEvent,
    headers: {
      ...noCacheHeaders,
    },
    data: {
      ...callBackData,
    },
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function submitRetailUserDetails(
  userDetails: SubmitUserDetailsRequest
): Promise<any> {
  return axiosInstance({
    method: "patch",
    url: paths.submitRetailUserDetails,
    headers: {
      ...noCacheHeaders,
    },
    data: {
      ...userDetails,
    },
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function submitUserQueryDetails(
  queryDetails: queryDetailsData
): Promise<queryDetailsData> {
  const logAQueryEndpoint = getConfigurationVariable(
    "VITE_APP_UNU_HEALTH_LOG_A_QUERY_ENDPOINT"
  );
  return axiosInstance({
    method: "post",
    url: logAQueryEndpoint,
    headers: {
      ...noCacheHeaders,
    },
    data: {
      firstName: queryDetails.firstName,
      lastName: queryDetails.lastName,
      phoneNumber: {
        globalSubscriberNumber: formatPhoneNumber(
          queryDetails.phoneNumber?.globalSubscriberNumber || "",
          queryDetails.phoneNumber?.countryCode
        ),
        countryCode: queryDetails.phoneNumber?.countryCode,
      },
      emailAddress: queryDetails.emailAddress,
      feedbackMessage: queryDetails.feedbackMessage,
      querySourceURI: queryDetails.querySourceURI,
    },
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function healthProfileNewEntry(
  questionType: string,
  answerPayload: any
): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.healthProfileNewEntry,
    headers: {
      ...noCacheHeaders,
    },
    data: {
      questionType: questionType,
      jsonDto: JSON.stringify(answerPayload.value, null, ""),
    },
  })
    .then((res: ReportIssueSuccessResponse) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

function formatReportIssueMessage({
  phoneNumber,
  errorType,
  memberId,
  identificationNumber,
}: {
  phoneNumber: string;
  errorType: string;
  memberId?: string;
  identificationNumber?: string;
}) {
  const memberIdMsg = memberId ? `MemberId: ${memberId} | ` : "";
  const phoneNumberMsg = phoneNumber
    ? `User entered phone: ${phoneNumber} | `
    : "";
  const identificationNumberMsg = identificationNumber
    ? `IdentificationNumber: ${identificationNumber} | `
    : "";

  return (
    phoneNumberMsg +
    memberIdMsg +
    identificationNumberMsg +
    `Flow: ${window.location.href} | Error type: ${errorType} | Date/Time: ${
      new Date().toISOString().split("T")[0]
    }`
  );
}

export function reportIssue({
  phoneNumber,
  errorType,
  memberId,
  identificationNumber,
  options,
}: {
  phoneNumber: string;
  errorType: string;
  memberId?: string;
  identificationNumber?: string;
  options?: RequestBaseOptions;
}): Promise<ReportIssueErrorResponseData | ReportIssueSuccessResponseData> {
  return axiosInstance({
    method: "post",
    url: paths.reportIssue,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
    data: {
      description: formatReportIssueMessage({
        phoneNumber,
        memberId,
        errorType,
        identificationNumber,
      }),
    },
  })
    .then((res: ReportIssueSuccessResponse) => {
      return Promise.resolve(res.data || { isError: false, message: "" });
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function getCommunicationConsent(
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "get",
    url: paths.getCommunicationConsent,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function getMemberServiceOfferingUsage(
  type: MemberServiceOfferingType,
  options?: RequestBaseOptions
): Promise<MemberServiceOfferingUsageSuccessResponse> {
  return axiosInstance({
    method: "get",
    url: paths.getMemberServiceOfferingUsage.replace("{type}", type),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return Promise.resolve(
        res.data as MemberServiceOfferingUsageSuccessResponse
      );
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function updateCommunicationConsent(
  communicationItems: any[],
  isOnboarding: boolean
): Promise<any> {
  return axiosInstance({
    method: "put",
    url: paths.updateCommunicationConsent,
    headers: {
      ...noCacheHeaders,
    },
    data: {
      communicationItems: communicationItems,
      isOnboarding: isOnboarding,
    },
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export function skipCommunicationConsent(): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.skipCommunicationConsent,
    headers: {
      ...noCacheHeaders,
    },
    data: {},
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export enum HealthRecordEntryType {
  ABBY = "ABBY",
  MEDICAL_DIAGNOSIS = "MEDICAL_DIAGNOSIS",
  WHATSAPP_NURSE_CHAT = "WHATSAPP_NURSE_CHAT",
  WHATSAPP_COUNSELLOR_CHAT = "WHATSAPP_COUNSELLOR_CHAT",
  MEDICINE_DISPENSED = "MEDICINE_DISPENSED",
  TELEMEDICINE_CONSULT = "TELEMEDICINE_CONSULT",
  FACE_SCAN = "FACE_SCAN",
  MEDICAL_ASSISTANCE = "MEDICAL_ASSISTANCE",
  CONSULTATION = "CONSULTATION",
  SWEETLIFE_BOT = "SWEETLIFE_BOT",
  PATHOLOGY = "PATHOLOGY",
}

export enum SubmitHealthRecordEventType {
  NURSE = "nurse",
  COUNSELLOR = "counsellor",
  SWEETLIFE = "sweetlife",
  COU_LABORATORY = "COU_LABORATORY",
  COU_PHARMACY = "COU_PHARMACY",
  COU_DOCTOR = "COU_DOCTOR",
  COU_CLINIC = "COU_CLINIC",
  COU_NURSE = "COU_NURSE",
}

export interface HealthRecordEvent {
  type: HealthRecordEntryType;
  name: string;
  description: string;
  dateTime: string;
  details: any;
}

interface HealthRecordSuccessResponseData {
  count: number;
  pageSize: number;
  pageIndex: number;
  events: HealthRecordEvent[];
}
interface HealthRecordErrorResponseData {
  error: {
    code: number;
    message: string;
    correlationId: string;
  };
}
export function getHealthRecord(
  sortDirection: SortDirection = "Desc",
  options?: RequestBaseOptions
): Promise<HealthRecordSuccessResponseData | HealthRecordErrorResponseData> {
  const urlWithParams = new URL(paths.healthRecord);
  urlWithParams.searchParams.append(
    "HealthProfileEventsSortColumn",
    "DateTime"
  );
  urlWithParams.searchParams.append("SortDirection", sortDirection);

  return axiosInstance({
    method: "get",
    url: urlWithParams.href,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      return Promise.reject(error.data);
    });
}

export enum MembershipChangeRequestIntent {
  CHANGE_PLAN = "CHANGE_PLAN",
  CANCEL_PLAN = "CANCEL_PLAN",
  UPDATE_DEPENDENT = "UPDATE_DEPENDENT",
  REMOVE_DEPENDENT = "REMOVE_DEPENDENT",
  ADD_DEPENDENT = "ADD_DEPENDENT",
}

export function submitMembershipChangeRequest(
  data: {
    intent: MembershipChangeRequestIntent;
    membershipIdentifier: string;
  },
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.submitMembershipChangeRequest,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
    data: {
      intent: data.intent,
      membershipID: data.membershipIdentifier,
    },
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getMembershipDependents(
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "get",
    url: paths.getMembershipDependents,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function submitMembershipDependents(
  data: {
    dependents: MembershipDependent[] | null;
  },
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.membershipDependents,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
    data: data.dependents,
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function updateMembershipDependents(
  data: {
    dependents: MembershipDependent[];
  },
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "patch",
    url: paths.membershipDependents,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
    data: data.dependents,
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function submitFaceScanMeasurements(
  data: {
    vitalSigns: VitalSigns | null;
    healthCheckQuestionnaireData: HealthCheckQuestionnaireData | null;
  },
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.submitFaceScanMeasurements,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
    data: {
      vitalSigns: cleanseVitalSigns(data.vitalSigns),
      userDemographics: data.healthCheckQuestionnaireData,
    },
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function submitPublicFaceScanData(
  data: {
    vitalSigns: VitalSigns | null;
    healthCheckQuestionnaireData: HealthCheckQuestionnaireData | null;
  },
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.submitPublicFaceScanData,
    headers: {
      ...noCacheHeaders,
      Authorization: `ApiKey ${String(import.meta.env.VITE_APP_UNU_HEALTH_API_KEY)}`,
    },
    signal: options?.signal,
    data: {
      vitalSigns: cleanseVitalSigns(data.vitalSigns),
      userDemographics: data.healthCheckQuestionnaireData,
    },
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getMyBenefits(
  options?: RequestBaseOptions
): Promise<Product[] | any> {
  return axiosInstance({
    method: "get",
    url: paths.getMyBenefits,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      const products = (res.data?.products?.map((product: Product) =>
        addFriendlyName(product)
      ) || []) as Product[];

      return products;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getPathologyPaymentRedirectURL(orderId: string) {
  return paths.pathologyPayment.replace("{id}", orderId);
}

export function initiatePathologyPayment(
  orderId: string,
  amount: number,
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "get",
    url: paths.initiatePathologyPayment
      .replace("{id}", orderId)
      .replace("{amount}", String(amount)),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  }).catch((error) => {
    throw new Error(error.data);
  });
}

export function getPathologyPanelsList(
  options?: RequestBaseOptions
): Promise<PathologyPanel[] | any> {
  return axiosInstance({
    method: "get",
    url: paths.getPathologyPanelsList,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      const panels = (res.data?.panels || []) as PathologyPanel[];
      return panels.sort((a, b) => a.orderIndex - b.orderIndex);
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getPathologyPanelById(
  id: string,
  options?: RequestBaseOptions
): Promise<PathologyPanel[] | any> {
  return axiosInstance({
    method: "get",
    url: paths.getPathologyPanelById.replace("{id}", id),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res?.data as PathologyPanel;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getPathologyOrderDetails(
  id: string,
  options?: RequestBaseOptions
): Promise<PathologyOrder[] | any> {
  return axiosInstance({
    method: "get",
    url: paths.getPathologyOrderDetails.replace("{id}", id),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return (res?.data || []) as PathologyOrder[];
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getPathologyPaymentReceipt(
  id: string,
  options?: RequestBaseOptions
): Promise<PathologyPaymentReceipt | any> {
  return axiosInstance({
    method: "get",
    url: paths.getPathologyPaymentReceipt.replace("{id}", id),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res?.data as PathologyPaymentReceipt;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function createPathologyOrder(
  panelId: string,
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.pathologyOrders,
    headers: {
      ...noCacheHeaders,
    },
    data: {
      panelId: panelId,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return (res?.data || []) as PathologyOrder[];
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getPathologyOrders(
  options?: RequestBaseOptions
): Promise<GetPathologyOrdersSuccessResponse> {
  return axiosInstance({
    method: "get",
    url: paths.pathologyOrders,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res?.data as GetPathologyOrdersSuccessResponse;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getMessages(
  skipCount?: number,
  listLength?: number,
  options?: RequestBaseOptions
): Promise<GetMessages | any> {
  return axiosInstance({
    method: "get",
    url: paths.getMessages
      .replace("{skipCount}", skipCount?.toString() || "0")
      .replace("{listLength}", listLength?.toString() || "10"),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function markAllMessagesAsRead(
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "post",
    url: paths.markAllMessagesAsRead,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function updateMessages(
  messages: UpdateMessage[],
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "patch",
    url: paths.updateMessage,
    headers: {
      ...noCacheHeaders,
    },
    data: messages,
    signal: options?.signal,
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function deleteMessage(
  messageID: string,
  options?: RequestBaseOptions
): Promise<any> {
  return axiosInstance({
    method: "delete",
    url: paths.deleteMessage.replace("{messageId}", messageID),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getMessageDetails(
  messageID: string,
  options?: RequestBaseOptions
): Promise<Message | any> {
  return axiosInstance({
    method: "get",
    url: paths.getMessageDetails.replace("{messageId}", messageID),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getDoctorList(
  options?: RequestBaseOptions
): Promise<Doctor[] | any> {
  return axiosInstance({
    method: "get",
    url: paths.getDoctorList,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return (res.data?.doctors || []) as Doctor[];
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getServiceOfferingUsage(
  options?: RequestBaseOptions
): Promise<GetServiceOfferingUsageSuccessResponseData | any> {
  return axiosInstance({
    method: "get",
    url: paths.getServiceOfferingUsage,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res.data as GetServiceOfferingUsageSuccessResponseData;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getMarketingChannels(
  status: Status,
  options?: RequestBaseOptions
): Promise<MarketingChannel[] | any> {
  return axiosInstance({
    method: "get",
    url: `${paths.getMarketingChannels}?status=${status}`,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return (res.data || []) as MarketingChannel[];
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function emailOptOut(
  data: {
    memberId: string;
    reason: string;
  },
  options?: RequestBaseOptions
): Promise<MarketingChannel[] | any> {
  return axiosInstance({
    method: "put",
    url: paths.emailOptOut.replace("{id}", data.memberId),
    headers: {
      ...noCacheHeaders,
    },
    data: {
      reason: data.reason,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res.data;
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getMarketingChannelsStores(
  data: GetMarketingChannelsStores,
  options?: RequestBaseOptions
): Promise<MarketingChannel[] | any> {
  return axiosInstance({
    method: "get",
    url: paths.getMarketingChannelsStores
      .replace("{id}", data.id)
      .replace("{province}", data.province),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return (res.data || []) as MarketingChannelStore[];
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getMarketingChannelsProvinces(
  id: string,
  options?: RequestBaseOptions
): Promise<string[] | any> {
  return axiosInstance({
    method: "get",
    url: paths.getMarketingChannelsProvinces.replace("{id}", id),
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return res.data || [];
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function getDirectToDoctorPrimarySymptoms(
  status: Status,
  options?: RequestBaseOptions
): Promise<DirectToDoctorPrimarySymptom[] | any> {
  return axiosInstance({
    method: "get",
    url: `${paths.getDirectToDoctorPrimarySymptoms}?status=${status}`,
    headers: {
      ...noCacheHeaders,
    },
    signal: options?.signal,
  })
    .then((res: any) => {
      return (res.data || []) as DirectToDoctorPrimarySymptom[];
    })
    .catch((error) => {
      throw new Error(error.data);
    });
}

export function logout() {
  return Promise.resolve(clearValue("authenticationResult"));
}

function getI18nTranslationKeyFromMessage(
  message: string | null | undefined
): string {
  if (!message) {
    message = "Unknown error";
  }

  if (message.indexOf("Member has been locked out for") > -1) {
    message = "User temporarily locked out";
  }

  const normalisedMessage = message.toLowerCase().split(" ").join("_");
  const translationKey = `UnuHealthAPI.messages.${normalisedMessage}`;

  return i18n.exists(translationKey) ? translationKey : message;
}

export function getI18nTranslationKeysFromMessages(
  messages: Array<string | null | undefined>
): Array<string> {
  return messages
    .filter((message) => {
      return message !== "" || message !== undefined || message !== null;
    })
    .map((message) => {
      return getI18nTranslationKeyFromMessage(message);
    });
}

const UNU_HEALTH_TERMS_AND_CONDITIONS_URL =
  import.meta.env.VITE_APP_UNU_HEALTH_API_BASE_URI +
  `/Contract/TERMS_AND_CONDITIONS_PLATFORM_{USER_SELECTED_LANGUAGE_CODE}/1/File.html`;

export function generatePlatformTsAndCsLinkBasedOnUserSelectedLanguage() {
  if (i18n.resolvedLanguage) {
    return UNU_HEALTH_TERMS_AND_CONDITIONS_URL.replace(
      "{USER_SELECTED_LANGUAGE_CODE}",
      i18n.resolvedLanguage
    )
      ?.replace("en", "ZA")
      ?.replace("pt-MZ", "MZ");
  }

  return UNU_HEALTH_TERMS_AND_CONDITIONS_URL.replace(
    "{USER_SELECTED_LANGUAGE_CODE}",
    "en"
  );
}

export function formatPhoneNumber(phoneNumber: string, countryCode?: string) {
  const country = findCountryBasedOnCountryCode(
    countryCode || supportedCountries[0].id
  );

  return `${country.internationalDiallingCode}${phoneNumber.replace(/^0/, "")}`;
}

const MeasurementValueRange: any = {
  BodyMassIndex: [
    {
      min: 30,
      max: 40,
      healthRiskIndicator: "OBESE",
    },
    {
      min: 25,
      max: 29.9,
      healthRiskIndicator: "OVERWEIGHT",
    },
    {
      min: 18.5,
      max: 24.9,
      healthRiskIndicator: "HEALTHY",
    },
    {
      min: 0,
      max: 18.5,
      healthRiskIndicator: "UNDERWEIGHT",
    },
  ],
  MetabolicAge: [
    {
      min: 55,
      max: 80,
      healthRiskIndicator: "BAD",
    },
    {
      min: 40,
      max: 55,
      healthRiskIndicator: "AVERAGE",
    },
    {
      min: 0,
      max: 40,
      healthRiskIndicator: "GOOD",
    },
  ],
  SystolicBloodPressure: [
    {
      min: 90,
      max: 120,
      healthRiskIndicator: "GOOD",
    },
    {
      min: 120,
      max: 130,
      healthRiskIndicator: "AVERAGE",
    },
    {
      min: 130,
      max: 140,
      healthRiskIndicator: "HIGH",
    },
    {
      min: 140,
      max: 160,
      healthRiskIndicator: "VERYHIGH",
    },
  ],
  DiastolicBloodPressure: [
    {
      min: 50,
      max: 80,
      healthRiskIndicator: "GOOD",
    },
    {
      min: 80,
      max: 90,
      healthRiskIndicator: "AVERAGE",
    },
    {
      min: 90,
      max: 120,
      healthRiskIndicator: "VERYHIGH",
    },
    {
      min: 120,
      max: 125,
      healthRiskIndicator: "DANGEROUS",
    },
  ],
  Weight: [
    {
      min: 0,
      max: 30,
      healthRiskIndicator: "ANOREXIA",
    },
    {
      min: 30,
      max: 40,
      healthRiskIndicator: "UNDERWEIGHT",
    },
    {
      min: 40,
      max: 60,
      healthRiskIndicator: "GOOD",
    },
    {
      min: 60,
      max: 70,
      healthRiskIndicator: "OVERWEIGHT",
    },
    {
      min: 70,
      max: 100,
      healthRiskIndicator: "OBESE",
    },
  ],
  BodyFat: [
    {
      min: 8,
      max: 19,
      healthRiskIndicator: "GOOD",
    },
    {
      min: 19,
      max: 25,
      healthRiskIndicator: "OVERWEIGHT",
    },
    {
      min: 25,
      max: 32,
      healthRiskIndicator: "OBESE",
    },
  ],
  BodyWaterPercent: [
    {
      min: 30,
      max: 50,
      healthRiskIndicator: "BELOWAVERAGE",
    },
    {
      min: 50,
      max: 65,
      healthRiskIndicator: "GOOD",
    },
    {
      min: 65,
      max: 85,
      healthRiskIndicator: "ABOVEAVERAGE",
    },
  ],
  MusclePercent: [
    {
      min: 60,
      max: 70,
      healthRiskIndicator: "GOOD",
    },
    {
      min: 70,
      max: 80,
      healthRiskIndicator: "HIGH",
    },
    {
      min: 80,
      max: 90,
      healthRiskIndicator: "VERYHIGH",
    },
  ],
};

export type HealthMeasurementQuestionType =
  | "MetabolicAge"
  | "BodyMassIndex"
  | "Weight"
  | "Height"
  | "SystolicBloodPressure"
  | "DiastolicBloodPressure"
  | "BodyFat"
  | "BodyWaterPercent"
  | "Pulse"
  | "MusclePercent"
  | "SaturationOfPeripheralOxygen"
  | "BloodType"
  | "SmokingStatus"
  | "SmokingFrequency"
  | "BreathingRate";

export interface HealthMeasurementDataValueRange {
  min: number;
  max: number;
  healthRiskIndicator: string;
}

export function getHealthMeasurementValueRange(
  type: HealthMeasurementQuestionType
): HealthMeasurementDataValueRange[] {
  return MeasurementValueRange[type];
}

export function findMembershipByMemberIdentifier(
  membershipIdentifier: string,
  memberships: Membership[]
): Membership | null {
  return (
    memberships.find((membership) => {
      return (
        membership.members.filter(
          (member: Member) =>
            member.membershipDetails.id === membershipIdentifier
        ).length > 0
      );
    }) || null
  );
}

export function findMainMemberInMembership(
  membership: Membership,
  loggedInUserMemberId: string
): Member | null {
  return (
    membership?.members?.find(
      (member) => member.memberDetails.id === loggedInUserMemberId
    ) || null
  );
}

export function findPrimaryCardMemberInMembership(
  membership: Membership | null
): Member | null {
  return (
    membership?.members?.find((member) => member.isPrimaryCard === true) || null
  );
}

export function findDependentsInMembership(membership: Membership): Member[] {
  return (
    membership?.members?.filter(
      (member) => member.membershipDetails.type !== MembershipType.MAIN_MEMBER
    ) || []
  );
}

export function findMembersOtherThanTheCurrentlyAuthenticatedMemberInMembership(
  membership: Membership | null
): Member[] {
  return (
    membership?.members?.filter((member) => member.isPrimaryCard === false) ||
    []
  );
}

export function findMemberByID(
  membership: Membership,
  membershipID: string | null
): Member | null {
  return (
    membership?.members?.find(
      (member) => member.membershipDetails.id === membershipID
    ) || null
  );
}

export function getProductPriceFromFormattedPremium(product: Product): number {
  const mainMemberProductPremium = findMainMemberProductPremium(product);
  const mainMemberPremiumFormatted =
    mainMemberProductPremium?.premiumFormatted || null;

  if (!isProductPremiumFree(mainMemberProductPremium)) {
    return Number(mainMemberPremiumFormatted?.split("R")[1]);
  }

  return 0;
}

export function sortProducts(products: Product[]): Product[] {
  const unsortedProducts = products;

  return unsortedProducts.sort((previousProduct, currentProduct) => {
    return (
      getProductPriceFromFormattedPremium(previousProduct) -
      getProductPriceFromFormattedPremium(currentProduct)
    );
  });
}

export function findNonFreePremiumProducts(product: Product): any {
  const filteredNonFreePremiumProducts =
    product.productPremiums?.find(
      (productPremium) => !isProductPremiumFree(productPremium)
    ) || null;

  return filteredNonFreePremiumProducts;
}

export function findMainMemberProductPremium(
  product: Product
): ProductPremium | null {
  const mainMemberProductPremium =
    product.productPremiums?.find(
      (productPremium) =>
        productPremium.memberType === MembershipType.MAIN_MEMBER
    ) || null;

  return mainMemberProductPremium;
}

export function getProductsWithBenefitsCount(product: Product): number {
  const benefitNames: string[] = [];
  product.benefitCategory?.forEach((benefitCategory) => {
    benefitCategory.benefits.forEach((benefit) => {
      benefitNames.push(benefit.name);
    });
  });
  return benefitNames.length;
}

export function isProductPremiumFree(premium: ProductPremium | null): boolean {
  return premium?.premiumFormatted?.toLowerCase() === "free";
}

export enum MembershipCardTemplateID {
  TEMPLATE1 = "TEMPLATE1",
  TEMPLATE2 = "TEMPLATE2",
  TEMPLATE3 = "TEMPLATE3",
}

export interface MembershipConfig {
  productCode: string;
  cardTemplateID: MembershipCardTemplateID;
  isHealthRecordSupported: boolean;
  isGetMedicalAssistanceSupported: boolean;
  membershipManagementSupportedActions: {
    CHANGE_PLAN: boolean;
    CANCEL_PLAN: boolean;
  };
  areAllMembershipManagementSupportedActionsEnabled: boolean | null;
  isIntercareSupported?: boolean;
}

export function getMembershipConfig(
  productCode: string | undefined | null
): MembershipConfig | null {
  const membershipConfigList = [
    {
      productCode: "UNKNOWN_PRODUCT_CODE",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: false,
      isGetMedicalAssistanceSupported: false,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "BEWELL 101",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "BEWELL-101",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "BEWELL 101+",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "BEWELL 102",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "BEWELL 103",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "BW101",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "B101P",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "BW102",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "BW103",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "GLDHR",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: true,
        CANCEL_PLAN: true,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "Khululekha",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE1,
      isHealthRecordSupported: false,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "Khululekha 01",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE1,
      isHealthRecordSupported: false,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "Khululekha 02",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE1,
      isHealthRecordSupported: false,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "Khululekha 03",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE1,
      isHealthRecordSupported: false,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "PRIST",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: true,
        CANCEL_PLAN: true,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "PRISTH",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE2,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: true,
        CANCEL_PLAN: true,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
    {
      productCode: "UNU01",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE3,
      isHealthRecordSupported: true,
      isGetMedicalAssistanceSupported: false,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: true,
        CANCEL_PLAN: true,
      },
      areAllMembershipManagementSupportedActionsEnabled: true,
      isIntercareSupported: true,
    },
    {
      productCode: "Uganda01",
      cardTemplateID: MembershipCardTemplateID.TEMPLATE1,
      isHealthRecordSupported: false,
      isGetMedicalAssistanceSupported: true,
      membershipManagementSupportedActions: {
        CHANGE_PLAN: false,
        CANCEL_PLAN: false,
      },
      areAllMembershipManagementSupportedActionsEnabled: null,
    },
  ].map((membershipConfig: MembershipConfig): MembershipConfig => {
    return {
      ...membershipConfig,
      areAllMembershipManagementSupportedActionsEnabled: Object.values(
        membershipConfig.membershipManagementSupportedActions || {}
      ).every((supportedAction) => {
        return supportedAction === true;
      }),
    };
  });

  if (!productCode) {
    return null;
  }

  return (
    membershipConfigList.find((item) => item.productCode === productCode) ||
    membershipConfigList[0]
  );
}

export function getDefaultPayGMedicalAssistanceAvailabilityData() {
  const AVAILABILITY_DATA = [
    {
      day: "MONDAY",
      operatingHours: "07:00 - 18:45",
    },
    {
      day: "TUESDAY",
      operatingHours: "07:00 - 18:45",
    },
    {
      day: "WEDNESDAY",
      operatingHours: "07:00 - 18:45",
    },
    {
      day: "THURSDAY",
      operatingHours: "07:00 - 18:45",
    },
    {
      day: "FRIDAY",
      operatingHours: "07:00 - 18:45",
    },
    {
      day: "SATURDAY",
      operatingHours: "08:00 - 14:45",
    },
    {
      day: "SUNDAY",
      operatingHours: "09:00 - 12:45",
    },
    {
      day: "HOLIDAY",
      operatingHours: "09:00 - 12:45",
    },
  ] as ProductAdministratorSupportedServiceChannelAvailability[];

  return AVAILABILITY_DATA;
}

export function getAvailableHealthCheckCount(
  healthChecks: ServiceOfferingUsage[]
) {
  return healthChecks.filter((option: ServiceOfferingUsage) => {
    const startDate = new Date(option?.availableDateRange.startDate).setHours(
      0,
      0,
      0,
      0
    );
    const endDate = new Date(option?.availableDateRange.endDate).setHours(
      0,
      0,
      0,
      0
    );
    const currentDate = new Date().setHours(0, 0, 0, 0);
    return (
      option.availabilityStatus !== ServiceOfferingUsageEnum.USED &&
      option.availabilityStatus !== ServiceOfferingUsageEnum.EXPIRED &&
      (startDate >= currentDate || endDate > currentDate)
    );
  }).length;
}

export function getMembershipCardConfigFromMembership(
  membership: Membership
): MembershipCardConfig {
  const member: Member | null = findPrimaryCardMemberInMembership(membership);

  return {
    friendlyName: membership.productDetails.friendlyName,
    memberFullName: member?.memberDetails.memberFullName || "",
    status: member?.membershipDetails.status || "",
    productCode: membership.productDetails.code,
    beneficiaryCode: member?.membershipDetails.beneficiaryCode || "",
    membershipNumber: member?.membershipDetails.number || "",
    product: membership.productDetails,
    pharmacySwitchCode: membership.productDetails.pharmacySwitchCode,
  };
}

export function requiresPreparationInstructions(
  panelChargeCode: PathologyPanelChargeCode
) {
  return [
    PathologyPanelChargeCode.MALE_OVER_45,
    PathologyPanelChargeCode.MALE_UNDER_45,
    PathologyPanelChargeCode.FEMALE,
    PathologyPanelChargeCode.DIABETES_MONITORING,
    PathologyPanelChargeCode.CHOLESTEROL_MONITORING,
    PathologyPanelChargeCode.BLOOD_PRESSURE_MONITORING,
  ].includes(panelChargeCode);
}
