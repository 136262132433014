/*
This is intentionally a JavaScript file, as it's used by Cypress as well as production code.
Cypress can't load Typescript files
*/
export function pathBuilder(config) {
  return {
    login: `${config.baseURI}/cognito/login`,
    loginCou: `${config.baseURI}/cognito/login-cou`,
    loginOtp: `${config.baseURI}/cognito/login-otp`,
    getNewAccessToken: `${config.baseURI}/cognito/refresh-token`,
    getContractFile: `${config.baseURI}/contract/{ContractType}/{Version}/file.html`,
    submitContractAgreement: `${config.baseURI}/contract/{ContractType}/{Version}/agreement`,
    getCommunicationConsent: `${config.baseURI}/communicationconsent/get-communication-consent`,
    updateCommunicationConsent: `${config.baseURI}/communicationconsent/upsert-communication-consent`,
    getMemberships: `${config.baseURI}/membership`,
    submitMembershipChangeRequest: `${config.baseURI}/membership/change-membership`,
    getMembershipDependents: `${config.baseURI}/member/dependents?status=active`,
    membershipDependents: `${config.baseURI}/member/dependents`,
    getServiceOfferingUsage: `${config.baseURI}/membership/membership-service-offering-usage`,
    health: `${config.baseURI}/health/get-health-check`,
    healthProfile: `${config.baseURI}/healthprofile/get-health-profile`,
    healthProfileNewEntry: `${config.baseURI}/healthprofile/new-entry`,
    submitFaceScanMeasurements: `${config.baseURI}/healthprofile/binah-submit-results`,
    healthRecord: `${config.baseURI}/healthprofile/events`,
    healthRecordEvent: `${config.baseURI}/healthprofile/event/{type}`,
    healthDataShare: `${config.baseURI}/health-data-share/{healthDataShareIdentifier}`,
    getMyBenefits: `${config.baseURI}/Product/my-benefits`,
    healthProfileHaveANurseCallMeBackCheck: `${config.baseURI}/member/request-nurse-call-back-check`,
    skipCommunicationConsent: `${config.baseURI}/member/skip-communication-consent`,
    getMember: `${config.baseURI}/member/get-member`,
    getMemberRequiredActions: `${config.baseURI}/member/required-actions`,
    getMemberServiceOfferingUsage: `${config.baseURI}/member/serviceoffering/{type}/usages?status=active`,
    submitMemberAcknowledgeEvent: `${config.baseURI}/member/acknowledge-event/{EventType}`,
    getMemberContactDetails: `${config.baseURI}/member/get-member-contact-details`,
    healthRecordHaveNurseCallMeBackEvent: `${config.baseURI}/member/request-nurse-call-back`,
    submitRetailUserDetails: `${config.baseURI}/member/member-personal-details`,
    getDoctorList: `${config.baseURI}/member/get-doctors`,
    startMemberConsultation: `${config.baseURI}/member/start-consultation`,
    getMemberProductPaymentRates: `${config.baseURI}/member/product-payment-rates`,
    getMemberConsultations: `${config.baseURI}/member/consultations`,
    getMemberConsultationOutcome: `${config.baseURI}/member/consultations/{ConsultationId}`,
    submitRating: `${config.baseURI}/member/service-ratings`,
    reportIssue: `${config.baseURI}/issue/create`,
    getMessages: `${config.notificationsBaseURI}/in-app?skipCount={skipCount}&listLength={listLength}`,
    getMessageDetails: `${config.notificationsBaseURI}/in-app/{messageId}`,
    deleteMessage: `${config.notificationsBaseURI}/in-app/{messageId}`,
    updateMessage: `${config.notificationsBaseURI}/in-app`,
    markAllMessagesAsRead: `${config.notificationsBaseURI}/in-app/mark-all-read`,
    getMarketingChannels: `${config.baseURI}/marketingchannel`,
    submitPublicFaceScanData: `${config.baseURI}/liberty/capture-vitals`,
    getDirectToDoctorPrimarySymptoms: `${config.baseURI}/condition/primary-symptoms`,
    getMemberProfile: `${config.baseURI}/member/profile`,
    getMarketingChannelsStores: `${config.baseURI}/marketingchannel/{id}/locations?province={province}`,
    getMarketingChannelsProvinces: `${config.baseURI}/marketingchannel/{id}/provinces`,
    getPathologyPanelsList: `${config.chronicCareBaseURI}/panels`,
    getPathologyOrderDetails: `${config.chronicCareBaseURI}/orders/{id}`,
    emailOptOut: `${config.baseURI}/communicationconsent/{id}/opt-out-email`,
    getPathologyPanelById: `${config.chronicCareBaseURI}/panels/{id}`,
    pathologyOrders: `${config.chronicCareBaseURI}/orders`,
    initiatePathologyPayment: `${config.baseURI}/pathology/initiate-order-payment?pathologyOrderId={id}&productAmount={amount}&productCode=BLOOD_WORK`,
    pathologyPayment: `${config.baseURI}/pathology/get-payment-page?pathologyOrderId={id}`,
    getPathologyPaymentReceipt: `${config.baseURI}/pathology/payment-receipt?pathologyOrderId={id}`,
  };
}
